import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";

const CustomAppBar = styled(AppBar)({
  backgroundColor: "white", // Solid black background
  padding: "10px 24px", // Added padding for breathing room
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)", // Lighter shadow for elegance
});

const LogoImage = styled("img")({
  height: "50px", // Logo height
});

const ContactInfo = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: "32px", // Increased gap for breathing space
  "@media (max-width: 600px)": {
    flexDirection: "column", // Stack vertically on smaller screens
    alignItems: "center",
    gap: "12px", // Smaller gap for mobile
  },
});

const ContactItem = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "8px",
  fontSize: "1rem", // Adjust font size
  "& svg": {
    fontSize: "1.2rem", // Adjust icon size to match text better
  },
});

const GoClutchHeader = () => {
  return (
    <CustomAppBar position="static">
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: "0px",
          paddingRight: "0px",
        }}
      >
        <Box
          sx={{ flexGrow: 1, display: "flex", justifyContent: "flex-start" }}
        >
          <LogoImage src="/common/company_logo.png" alt="GoClutch Logo" />
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <ContactInfo>
          <ContactItem>
            <PhoneIcon sx={{ color: 'black'}} />
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", color: "black" }}
            >
              +91-91339 59551
            </Typography>
          </ContactItem>
          <ContactItem>
            <EmailIcon sx={{ color: 'black'}} />
            <Typography variant="body1" style={{ fontWeight: "bold", color: "black" }}>
              Support@goclutch.in
            </Typography>
          </ContactItem>
        </ContactInfo>
      </Toolbar>
    </CustomAppBar>
  );
};

export default GoClutchHeader;
