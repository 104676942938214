import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import "./App.css";
import GoClutchPage from "./components"; // Assuming this is the correct import for GoClutchPage
import LoadingDots from "./components/initialLoad";
import { Fab } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";

function App() {
  const [showAnimation, setShowAnimation] = useState(true);

  useEffect(() => {
    // Hide the animation after 5 seconds
    const timer = setTimeout(() => {
      setShowAnimation(false);
    }, 2000); // Match the duration of your CarServiceAnimation

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault(); // Prevent the default right-click menu
    };

    document.addEventListener("contextmenu", handleContextMenu);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
    
  }, []);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      {showAnimation ? (
        <LoadingDots />
      ) : (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<GoClutchPage />} />
          </Routes>
        </BrowserRouter>
      )}
    </>
  );
}

export default App;
