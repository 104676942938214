import React, { useState } from "react";
import {
  CardMedia,
  Dialog,
  DialogContent,
  IconButton,
  useMediaQuery,
  Box,
} from "@mui/material";
import { FaMapMarkerAlt, FaTools, FaOilCan, FaShieldAlt } from "react-icons/fa";
import { FaGears } from "react-icons/fa6";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import "./index.scss";

const styles = {
  playButton: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "white",
    bgcolor: "red",
    "&:hover": {
      bgcolor: "darkred",
    },
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.5s infinite ease-in-out",
      border: "10px solid currentColor",
      boxSizing: "border-box",
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  },
  dialogContent: {
    padding: 0,
    "&:first-child": {
      paddingTop: 0,
    },
  },
};

const BenifitsWrapper = () => {
  const isDesktop = useMediaQuery("(min-width:768px)");

  const [open, setOpen] = useState(false);

  const handlePlayButtonClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const videoId = "8whOjH0FO_I";
  const videoSrc = `https://www.youtube.com/embed/${videoId}?autoplay=1&rel=0`;

  return (
    <section style={{ marginTop: isDesktop ? "24px" : '' }}>
      <div>
        <h4 className="service-header">
          Select and Book a Quality Car Service <br />
           And <br /> 
           Get Exciting Offer on every
          Car Service and Repairs.
        </h4>
      </div>
      <div className="benifits-wrapper">
        <div style={{ marginTop: "auto", marginBottom: "auto" }}>
          <h4 className="benifits-header">Go Clutch Benefits</h4>
          <div style={{ display: "flex", marginTop: "24px" }}>
            <FaMapMarkerAlt className="benifits-icon" />
            <div>
              <h4 className="benifits-heading">
                Free Door Step Pick-up and Drop
              </h4>
              <p className="benifits-helper">
                Do away with the Hassle of Stepping Out.
              </p>
            </div>
          </div>
          <div style={{ display: "flex", marginTop: "24px" }}>
            <FaTools className="benifits-icon" />
            <div>
              <h4 className="benifits-heading">Free 50 Points Inspection</h4>
              <p className="benifits-helper">
                360 degrees Quality Inspection and Live Updates.
              </p>
            </div>
          </div>
          <div style={{ display: "flex", marginTop: "24px" }}>
            <FaGears className="benifits-icon" />
            <div>
              <h4 className="benifits-heading">100% Genuine Spares</h4>
              <p className="benifits-helper">
                Only Company recommended Spares used. Quality Guaranteed.
              </p>
            </div>
          </div>
          <div style={{ display: "flex", marginTop: "24px" }}>
            <FaShieldAlt className="benifits-icon" />
            <div>
              <h4 className="benifits-heading">35 Days Warranty</h4>
              <p className="benifits-helper">
                35 Days/1000 Km Warranty on Car Service.
              </p>
            </div>
          </div>
        </div>
        <Box
          sx={{
            position: "relative",
            width: !isDesktop ? "100%" : "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CardMedia
            component="img"
            sx={{
              width: "100%",
              height: !isDesktop ? "300px" : "500px",
              objectFit: "cover",
              borderRadius: "20px",
            }}
            image="https://res.cloudinary.com/dgesamtia/image/upload/v1724668708/Red_and_White_Modern_Auto_Tires_Service_Instagram_Post_fyxzxw.png"
            alt="Mechanics working"
          />
          <IconButton
            sx={{
              ...styles.playButton,
              fontSize: !isDesktop ? "2rem" : "3rem",
            }}
            onClick={handlePlayButtonClick}
          >
            <PlayCircleFilledWhiteIcon
              sx={{ fontSize: !isDesktop ? "2.5rem" : "3rem" }}
            />
          </IconButton>
          <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="md"
            fullWidth
            sx={styles.dialog}
          >
            <DialogContent sx={styles.dialogContent}>
              <iframe
                width="100%"
                height="500"
                src={videoSrc}
                allow="autoplay; encrypted-media"
                allowFullScreen
                title="Embedded youtube"
              />
            </DialogContent>
          </Dialog>
        </Box>
      </div>
    </section>
  );
};

export default BenifitsWrapper;
