import React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { Card, Typography, Button } from "@mui/material";

const MotionTypography = motion(Typography);

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  margin-bottom: 1rem;
  border-radius: 25px !important;
  border: 2px solid orangered !important;
  background: rgba(255, 255, 255, 0.25) !important;
  backdrop-filter: blur(10px);
  color: #ffffff;
  box-shadow: 0 10px 30px rgba(255, 165, 0, 0.5);
  transition: transform 0.3s ease-in-out;
  height: 300px;
  width: 250px;
  overflow: hidden;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 20px 40px rgba(255, 165, 0, 0.5); // Enhanced shadow on hover
  }
`;

const ServicesListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  padding: 4rem;
  justify-items: center;
`;

const ServicesSectionContainer = styled.section`
  // background-image: url("/serviceIcons/service-background.webp");
  background-size: cover;
  background-position: center;
  padding: 1rem;
  text-align: center;
  margin-top: 6rem;
`;

const ButtonContainer = styled.div`
  margin-top: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const cardVariants = {
  offscreen: {
    y: 50,
    opacity: 0,
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8,
    },
  },
};

const Description = styled(Typography)`
  margin-bottom: 1rem;
  text-align: center;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
  height: 120px;
  z-index: 2;
`;

const StyledIcon = styled.img`
  width: 100px;
  height: auto;
  filter: drop-shadow(2px 4px 6px grey);
  z-index: 3;
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
`;

const Container = styled.div`
  margin: 0 auto;
  padding: 2rem;
`;

const ServiceCard = ({ name, img }) => {
  console.log("img", img);

  return (
    <>
      <motion.div
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.8 }}
        variants={cardVariants}
      >
        <StyledCard>
          <>
            <Typography variant="h5" gutterBottom sx={{ fontSize: "20px" }}>
              {name}
            </Typography>
            <IconContainer>
              <StyledIcon src={img} alt="Service Icon" />
            </IconContainer>
          </>
          <ButtonContainer>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => window.open("https://goclutch.in", "_blank")}
              sx={{ marginBottom: "8px", fontWeight: 'bold' }}
            >
              Know More
            </Button>
            {/* <Button variant="contained" sx={{ background: "orangered" }}>
              Book Now
            </Button> */}
          </ButtonContainer>
        </StyledCard>
      </motion.div>
    </>
  );
};

const DesktopServicesList = ({ services }) => (
  <>
    <ServicesSectionContainer>
      <MotionTypography
        variant="h4"
        component="h2"
        style={{ marginBottom: "20px", textAlign: "center" }}
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        Go Clutch - Premium Car Services and Mechanical Repairs
      </MotionTypography>
      <MotionTypography
        variant="body1"
        style={{
          textAlign: "center",
          color: "grey",
          padding: "0 4rem",
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5, duration: 0.8 }}
      >
        Discover Our Wide Range of Expert Car Services & Repairs
      </MotionTypography>
      <ServicesListContainer>
        {services.map((service, index) => (
          <ServiceCard key={index} name={service.name} img={service.img} />
        ))}
      </ServicesListContainer>
    </ServicesSectionContainer>
  </>
);

export default DesktopServicesList;
