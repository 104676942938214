import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Card,
  CardContent,
  Collapse,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ConstructionIcon from "@mui/icons-material/Construction";
import SettingsIcon from "@mui/icons-material/Settings";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import CarRentalIcon from '@mui/icons-material/CarRental';
import { keyframes } from "@emotion/react";
import "./index.scss";

// Keyframes for icon animation
const bounceIn = keyframes`
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.9;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const MobileView = () => {
  const [expandedStep, setExpandedStep] = useState(null);

  const steps = [
    {
      Icon: CarRentalIcon,
      title: "Free Pick-Up",
      description: "We collect your car from your location at no extra cost.",
    },
    {
      Icon: SettingsIcon,
      title: "Detailed Inspection",
      description:
        "Our experts conduct a thorough check to identify any issues.",
    },
    {
      Icon: HomeWorkIcon,
      title: "Get Serviced",
      description:
        "Your car receives top-quality service from expert Mechanics",
    },
    {
      Icon: ConstructionIcon,
      title: "Same-Day Delivery",
      description:
        "Enjoy the convenience of returning your car on the same day.",
    },
  ];

  const toggleExpansion = (index) => {
    setExpandedStep(expandedStep === index ? null : index);
  };

  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography
        variant="h4"
        component="h3"
        align="center"
        gutterBottom
        sx={{
          fontWeight: "700",
          mb: 4,
          fontSize: "1.8rem",
          letterSpacing: "0.05rem",
          color: "#4a4a4a",
        }}
      >
        Our Work Process
      </Typography>
      {steps.map((step, index) => (
        <Card
          key={index}
          sx={{
            width: "100%",
            mb: 3,
            maxWidth: 360,
            background: "linear-gradient(145deg, #f5f5f5, #ffffff)",
            borderRadius: "12px",
            transition: "transform 0.3s, box-shadow 0.3s",
            "&:hover": {
              transform: "scale(1.03)",
              boxShadow: "0 8px 16px rgba(0,0,0,0.15)",
            },
          }}
          onClick={() => toggleExpansion(index)}
          elevation={5}
        >
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <IconButton
              sx={{
                backgroundColor: "rgba(255, 87, 34, 0.1)",
                padding: "12px",
                borderRadius: "50%",
                mb: 2,
                animation: `${bounceIn} 0.6s ease`,
              }}
            >
              <step.Icon sx={{ fontSize: 40, color: "orangered" }} />
            </IconButton>
            <Typography variant="h6" sx={{ mt: 1, fontWeight: "600" }}>
              {step.title}
            </Typography>
            <ExpandMoreIcon
              sx={{
                transform:
                  expandedStep === index ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform 0.3s",
                mt: 1,
              }}
            />
          </CardContent>
          <Collapse in={expandedStep === index} timeout="auto" unmountOnExit>
            <CardContent sx={{ pt: 0 }}>
              <Typography variant="body2" sx={{ color: "#555" }}>
                {step.description}
              </Typography>
            </CardContent>
          </Collapse>
        </Card>
      ))}
    </Box>
  );
};

export default MobileView;
