import React, { useState } from "react";
import { Button } from "@mui/material";
import "./Services.css";

const MobileServicesList = () => {
  const [services] = useState([
    {
      name: "Periodic Services",
      img: "/serviceIcons/service5.png",
    },
    {
      name: "Denting & Painting",
      img: "/serviceIcons/service3.png",
    },
    {
      name: "Major Services",
      img: "/serviceIcons/service7.png",
    },
    {
      name: "AC Services",
      img: "/serviceIcons/service1.png",
    },
    {
      name: "Car Detailing",
      img: "/serviceIcons/service6.png",
    },
    {
      name: "Tire Maintenance",
      img: "/serviceIcons/service11.png",
    },
    { name: "Car Spa", img: "/serviceIcons/service4.png" },
    {
      name: "Clutch & Suspension",
      img: "/serviceIcons/service9.png",
    },
  ]);

  const handleButton = () => {
    alert(
      "Hey! we are expanding our services. For now you can request a callback by filling our default form at top"
    );
  };

  return (
    <>
      <header className="services-header">
        <h1>Go Clutch - Premium Car Services and Mechanical Repairs</h1>
        <p>Discover Our Wide Range of Expert Car Services & Repairs</p>
      </header>
      <div className="grid-services-container">
        {services.map((service) => (
          <div key={service.id} className="service-card">
            <div className="service-overlay">
              <div className="service-info">
                <div className="service-actions">
                  <Button
                    sx={{
                      fontSize: { xs: "10px" },
                      marginTop: "8px",
                    }}
                    variant="outlined"
                    onClick={() =>
                      window.open(
                        "https://goclutch.in",
                        "_blank"
                      )
                    }
                  >
                    Know More
                  </Button>
                  {/* <Button
                    onClick={handleButton}
                    sx={{
                      fontSize: { xs: "10px" },
                      mt: { xs: "16px" },
                      mb: { xs: "24px" },
                      mx: { xs: "16px" },
                      background: "orangered",
                    }}
                    variant="contained"
                  >
                    Book Now
                  </Button> */}
                </div>
              </div>
            </div>
            <div className="service-background">
              <img
                className="service-icon"
                src={service.img}
                alt="Service Icon"
              />
              <span className="service-name">{service.name}</span>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default MobileServicesList;
