import React from "react";
import { Box, Container, Typography, Grid } from "@mui/material";
// import LightbulbOutlineIcon from "@mui/icons-material/LightbulbOutline";
import ConstructionIcon from "@mui/icons-material/Construction";
import SettingsIcon from "@mui/icons-material/Settings";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import CarRentalIcon from '@mui/icons-material/CarRental';

const DesktopView = () => {
  return (
    <Box sx={{ backgroundColor: "#f9f9f9", p: 4 }}>
      <Typography
        variant="h4"
        component="h3"
        align="center"
        gutterBottom
        sx={{ fontWeight: "bold", color: '#4a4a4a' }}
      >
        Our Work Process
      </Typography>
      <Container maxWidth="lg">
        <Grid container spacing={2} justifyContent="center" alignItems="start">
          <Grid item xs={12} sm={6} md={3}>
            <Box
              sx={{
                position: "relative",
                textAlign: "center",
                mb: 4,
                transition: "background-color 0.3s",
                "&:hover": {
                  "#constructionIdIcon": {
                    backgroundColor: "orangered",
                    color: "white !important",
                  },
                },
                "&::after": {
                  content: '""',
                  display: "block",
                  width: "96px",
                  height: "36px",
                  backgroundImage: 'url("/common/downArrow.svg")',
                  backgroundRepeat: "no-repeat",
                  position: "absolute",
                  top: "30px",
                  right: "-48px",
                },
              }}
            >
              <Box>
                <Box
                  sx={{
                    display: "inline-block",
                    p: 4,
                    borderRadius: "50%",
                    backgroundColor: "white",
                    color: "orangered",
                    transition: "background-color 0.3s",
                  }}
                  id="constructionIdIcon"
                >
                  <CarRentalIcon sx={{ fontSize: 48 }} />
                </Box>
                {/* <img
                  src="/common/downArrow.svg"
                  alt="Up Arrow"
                  style={{ marginLeft: "24px" }}
                /> */}
              </Box>
              <Typography variant="h6" component="h4" sx={{ mt: 2 }}>
                Free Pick-Up
              </Typography>
              <Typography variant="body1">
                We collect your car from your location at no extra cost.
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{ position: "relative", top: "64px" }}
          >
            <Box
              sx={{
                position: "relative",
                textAlign: "center",
                mb: 4,
                transition: "background-color 0.3s",
                "&:hover": {
                  "#constructionIdIcon1": {
                    backgroundColor: "orangered",
                    color: "white !important",
                  },
                },
                "&::after": {
                  content: '""',
                  display: "block",
                  width: "96px",
                  height: "36px",
                  backgroundImage: 'url("/common/upArrow.svg")',
                  backgroundRepeat: "no-repeat",
                  position: "absolute",
                  top: "30px",
                  right: "-48px",
                },
              }}
            >
              <Box
                sx={{
                  display: "inline-block",
                  p: 4,
                  borderRadius: "50%",
                  backgroundColor: "white",
                  color: "orangered",
                }}
                id="constructionIdIcon1"
              >
                <ConstructionIcon sx={{ fontSize: 48 }} />
              </Box>
              <Typography variant="h6" component="h4" sx={{ mt: 2 }}>
                Detailed Inspection
              </Typography>
              <Typography variant="body1">
                Our experts conduct a thorough check to identify any issues.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box
              sx={{
                position: "relative",
                textAlign: "center",
                mb: 4,
                transition: "background-color 0.3s",
                "&:hover": {
                  "#settingsIdIcon": {
                    backgroundColor: "orangered",
                    color: "white !important",
                  },
                },
                "&::after": {
                  content: '""',
                  display: "block",
                  width: "96px",
                  height: "36px",
                  backgroundImage: 'url("/common/downArrow.svg")',
                  backgroundRepeat: "no-repeat",
                  position: "absolute",
                  top: "30px",
                  right: "-48px",
                },
              }}
            >
              <Box
                sx={{
                  display: "inline-block",
                  p: 4,
                  borderRadius: "50%",
                  backgroundColor: "white",
                  color: "orangered",
                }}
                id="settingsIdIcon"
              >
                <SettingsIcon sx={{ fontSize: 48 }} />
              </Box>
              <Typography variant="h6" component="h4" sx={{ mt: 2 }}>
                Get Serviced
              </Typography>
              <Typography variant="body1">
                Your car receives top-quality service from expert Mechanics
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{ position: "relative", top: "64px" }}
          >
            <Box
              sx={{
                textAlign: "center",
                mb: 4,
                transition: "background-color 0.3s",
                "&:hover": {
                  "#homeIdIcon": {
                    backgroundColor: "orangered",
                    color: "white !important",
                  },
                },
              }}
            >
              <Box
                sx={{
                  display: "inline-block",
                  p: 4,
                  borderRadius: "50%",
                  backgroundColor: "white",
                  color: "orangered",
                }}
                id="homeIdIcon"
              >
                <HomeWorkIcon sx={{ fontSize: 48 }} />
              </Box>
              <Typography variant="h6" component="h4" sx={{ mt: 2 }}>
                Same-Day Delivery
              </Typography>
              <Typography variant="body1">
                Enjoy the convenience of returning your car on the same day.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default DesktopView;
