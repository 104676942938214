import { useMediaQuery } from "@mui/material";
import MobileServicesList from "./mobileView";
import DesktopServicesList from "./desktopView";

const MajorServices = () => {
  const isDesktop = useMediaQuery("(min-width:768px)");
  const services = [
    {
      name: "Periodic Services",
      img: "/serviceIcons/service5.png",
    },
    {
      name: "Denting & Painting",
      img: "/serviceIcons/service3.png",
    },
    {
      name: "Major Services",
      img: "/serviceIcons/service7.png",
    },
    {
      name: "AC Services",
      img: "/serviceIcons/service1.png",
    },
    {
      name: "Car Detailing",
      img: "/serviceIcons/service6.png",
    },
    {
      name: "Tire Maintenance",
      img: "/serviceIcons/service11.png",
    },
    { name: "Car Spa", img: "/serviceIcons/service4.png" },
    {
      name: "Clutch & Suspension",
      img: "/serviceIcons/service9.png",
    },
  ];

  return (
    <>
      {isDesktop ? (
        <DesktopServicesList services={services} />
      ) : (
        // <MobileServicesList services={services} />
        <MobileServicesList />
      )}
    </>
  );
};

export default MajorServices;
