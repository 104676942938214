import React from "react";
import {
  Grid,
  TextField,
  Button,
  Typography,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Star, Build } from "@mui/icons-material"; // Icons for bottom text
import CarouselComponent from "./carousel";
import FormCarousel from "./Form";

const CarouselWrapper = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Grid
        container
        sx={{
          height: !isMobile ? "100vh" : "",
          padding: 3,
          maxWidth: "100vw",
          overflowX: "hidden",
        }}
      >
        {/* Right Section - Image */}
        <Grid
          item
          xs={12}
          md={8} // Use 60% of the width
          order={{ xs: 1, md: 2 }} // Order: first on mobile, second on desktop
          container
          justifyContent="center"
          alignItems={{ xs: "flex-start", md: "center" }} // Align at the top for mobile
          sx={{
            display: "flex",
            height: "100%",
            textAlign: "center",
            paddingBottom: { xs: 5, md: 0 }, // Padding for bottom space on mobile
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Box
              sx={{
                maxWidth: "100%",
                maxHeight: "100%",
                width: "auto",
                height: "auto",
              }}
            >
              <CarouselComponent />
            </Box>
          </Box>
        </Grid>

        {/* Left Section */}
        <Grid
          item
          xs={12}
          md={4} // Use 40% of the width
          order={{ xs: 2, md: 1 }} // Order: second on mobile, first on desktop
          container
          direction="column"
          justifyContent={{ xs: "flex-start", md: "center" }} // Align at the top for mobile
          alignItems="center"
          sx={{
            paddingBottom: { xs: 5, md: 0 }, // Adjust bottom padding for mobile
            paddingTop: { xs: 0, md: 0 }, // Adjust top padding for mobile
          }}
        >
          {/* Headline */}
          <Typography
            variant="h4"
            sx={{ fontWeight: "bold", textAlign: "center", marginBottom: 2 }}
          >
            <span style={{ color: "orangered" }}>Go Clutch</span> : Car Service
            Simplified
          </Typography>
          <Typography
            variant="h5"
            sx={{ textAlign: "center", marginBottom: 4, color: "#333" }}
          >
            <strong style={{ color: "orangered" }}>INR 1000 OFF</strong> on All
            Car Services
          </Typography>

          {/* Form Container */}
          <FormCarousel />

          {/* Discount Information */}
          <Box
            sx={{
              marginTop: 4,
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              gap: 4,
              flexWrap: { xs: "wrap", md: "nowrap" }, // Wrap items on mobile
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Star sx={{ color: "#FFC107" }} />
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                4.5+ Rated Trusted Garages
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Build sx={{ color: "orangered" }} />
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                <span style={{ color: "orangered" }}>Go Clutch</span> Certified
                Mechanics
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default CarouselWrapper;
