import React from 'react';

const LoadingDots = () => {
  const centerDotStyle = {
    width: '20px',  // Largest central dot
    height: '20px',
    margin: '10px',
    backgroundColor: 'orange',
    borderRadius: '50%',
    animation: 'bubble 1.4s infinite ease-in-out both'
  };

  const sideDotStyle = index => ({
    width: `${20 - index * 4}px`,  // Decreasing size for side dots
    height: `${20 - index * 4}px`,
    margin: '10px',
    backgroundColor: 'orange',
    borderRadius: '50%',
    animation: 'bubble 1.4s infinite ease-in-out both'
  });

  const loadingDotsStyle = {
    display: 'flex',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    alignItems: 'center',
    justifyContent: 'center'
  };

  return (
    <>
      <style>{`
        @keyframes bubble {
          0%, 100% {
            transform: scale(0.5);
          }
          50% {
            transform: scale(1.25);
          }
        }
      `}</style>
      <div style={loadingDotsStyle}>
        <div style={sideDotStyle(3)}></div>
        <div style={sideDotStyle(2)}></div>
        <div style={sideDotStyle(1)}></div>
        <div style={centerDotStyle}></div>
        <div style={sideDotStyle(1)}></div>
        <div style={sideDotStyle(2)}></div>
        <div style={sideDotStyle(3)}></div>
      </div>
    </>
  );
};

export default LoadingDots;
