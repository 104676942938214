import React from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { CheckCircle, Cancel } from "@mui/icons-material";

const ComparisonComponent = () => {
  const isMobile = useMediaQuery("(max-width:600px)");

  const benefits = [
    {
      title: "Cost Efficiency",
      localService: "Expensive pricing",
      goClutch: "Affordable Pricing",
    },
    {
      title: "Quality of Service",
      localService: "Certified Mechanics",
      goClutch: "Certified Mechanics",
    },
    {
      title: "Warranty",
      localService: "Limited to Manufacturer Parts",
      goClutch: "Extended Warranty on Parts",
    },
    {
      title: "Convenience",
      localService: "Inflexible Appointments",
      goClutch: "Pick-up/Drop-off Service",
    },
    {
      title: "Customer Support",
      localService: "Standard Support Hours",
      goClutch: "24/7 customer Support",
    },
  ];

  return (
    <Box sx={{ paddingY: 4, backgroundColor: "#f5f5f5", borderRadius: 2 }}>
      <Typography
        variant="h4"
        sx={{ fontWeight: "bold", textAlign: "center", marginBottom: 4, color: '#4a4a4a' }}
      >
        Why Choose Go Clutch?
      </Typography>

      {isMobile ? (
        <Card sx={{ boxShadow: 3, paddingY: 3 }}>
          <CardContent>
            <Grid
              container
              spacing={2}
              sx={{ marginBottom: 2 }}
              alignItems="center"
            >
              <Grid item xs={6}>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    color: "#D32F2F",
                    textAlign: "left",
                  }}
                >
                  Local Service center
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    color: "#388E3C",
                    textAlign: "left",
                  }}
                >
                  Go Clutch Service center
                </Typography>
              </Grid>
            </Grid>
            {benefits.map((benefit, index) => (
              <Box key={index}>
                <Grid container alignItems="center" spacing={0}>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      backgroundColor: "#F8D7DA", // light red background
                      padding: "12px",
                      borderRadius: "8px 0 0 8px",
                    }}
                  >
                    <Cancel
                      sx={{
                        color: "white",
                        backgroundColor: "#D32F2F",
                        borderRadius: "50%",
                        padding: "4px",
                        marginRight: 1,
                      }}
                    />
                    <Typography
                      color="text.secondary"
                      sx={{ textAlign: "left", fontWeight: "bold" }}
                    >
                      {benefit.localService}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      backgroundColor: "#D4EDDA", // light green background
                      padding: "12px",
                      borderRadius: "0 8px 8px 0",
                    }}
                  >
                    <CheckCircle
                      sx={{
                        color: "white",
                        backgroundColor: "#388E3C",
                        borderRadius: "50%",
                        padding: "4px",
                        marginRight: 1,
                      }}
                    />
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#388E3C",
                        textAlign: "left",
                      }}
                    >
                      {benefit.goClutch}
                    </Typography>
                  </Grid>
                </Grid>
                {index < benefits.length - 1 && <Divider sx={{ marginY: 3 }} />}{" "}
                {/* Divider between rows */}
              </Box>
            ))}
          </CardContent>
        </Card>
      ) : (
        <Grid
          container
          spacing={4}
          justifyContent="center"
          sx={{ paddingLeft: "24px", paddingRight: "24px" }}
        >
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                boxShadow: 3,
                padding: 3,
                backgroundColor: "#F8D7DA",
              }}
            >
              {" "}
              {/* light red background */}
              <CardContent>
                <Typography
                  variant="h5"
                  sx={{
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "#D32F2F",
                    marginBottom: 3,
                  }}
                >
                  Local Service Center
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      left: "50%",
                      top: 0,
                      bottom: 0,
                      width: "2px",
                      backgroundColor: "orangered",
                      transform: "translateX(-50%)",
                    }}
                  ></Box>

                  <Box
                    sx={{
                      width: "50%",
                      display: "flex",
                      flexDirection: "column",
                      paddingRight: 4,
                    }}
                  >
                    {benefits.map((benefit, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          height: "50px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            textAlign: "right",
                            width: "100%",
                            paddingRight: 2,
                          }}
                        >
                          {benefit.title}
                        </Typography>
                      </Box>
                    ))}
                  </Box>

                  <Box
                    sx={{
                      width: "50%",
                      display: "flex",
                      flexDirection: "column",
                      paddingLeft: 4,
                    }}
                  >
                    {benefits.map((benefit, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          height: "50px",
                        }}
                      >
                        <Cancel color="error" />
                        <Typography
                          color="text.secondary"
                          sx={{ marginLeft: 1 }}
                        >
                          {benefit.localService}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card sx={{ boxShadow: 3, padding: 3, backgroundColor: "#D4EDDA" }}>
              {" "}
              {/* light green background */}
              <CardContent>
                <Typography
                  variant="h5"
                  sx={{
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "#388E3C",
                    marginBottom: 3,
                  }}
                >
                  Go Clutch Service Center
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      left: "50%",
                      top: 0,
                      bottom: 0,
                      width: "2px",
                      backgroundColor: "orangered",
                      transform: "translateX(-50%)",
                    }}
                  ></Box>

                  <Box
                    sx={{
                      width: "50%",
                      display: "flex",
                      flexDirection: "column",
                      paddingRight: 4,
                    }}
                  >
                    {benefits.map((benefit, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          height: "50px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            textAlign: "right",
                            width: "100%",
                            paddingRight: 2,
                          }}
                        >
                          {benefit.title}
                        </Typography>
                      </Box>
                    ))}
                  </Box>

                  <Box
                    sx={{
                      width: "50%",
                      display: "flex",
                      flexDirection: "column",
                      paddingLeft: 4,
                    }}
                  >
                    {benefits.map((benefit, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          height: "50px",
                        }}
                      >
                        <CheckCircle color="success" sx={{ marginRight: 1 }} />
                        <Typography
                          sx={{ fontWeight: "bold", color: "#388E3C" }}
                        >
                          {benefit.goClutch}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default ComparisonComponent;
