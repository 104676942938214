import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import MobileView from './smallView';
import DesktopView from './largeView';

const WorkProcessComponent = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Adjust breakpoint as needed

  return (
    <>
      {isMobile ? <MobileView /> : <DesktopView />}
    </>
  );
};

export default WorkProcessComponent;
