import React from "react";
import WorkProcessComponent from "./HowWeWorks";
import BenifitsWrapper from "./Benifits";
import MajorServices from "./feature_services";
import FooterWrapper from "./footer";
import ComparisonComponent from "./Benifits/comparision";
import CarouselWrapper from "./carousel";
import TestimonialSlider from "./Testimonials";
import GoClutchHeader from "./Header";

const GoClutchPage = () => {
  return (
    <>
      <GoClutchHeader />
      <CarouselWrapper />
      <ComparisonComponent />
      <MajorServices />
      <WorkProcessComponent />
      <BenifitsWrapper />
      <TestimonialSlider />
      <FooterWrapper />
    </>
  );
};

export default GoClutchPage;
