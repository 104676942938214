import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Card,
  CardContent,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
} from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";
import SwipeableViews from "react-swipeable-views";

// Mock testimonials data
const testimonials = [
  {
    name: "Harish T",
    title: "Madhapur branch",
    text: "I have recently got my alloy custom painting job done here at the Madhapur branch. I got very good assistance from Sandeep from goclutch, he assisted me with nearby branches and the possible pricing details proactively. Once the job is created on Madhapur branch, person Kamal contacted me he is expert in that branch and he is a real good guy he has a lot of patience and he answered all my queries very patiently and given me the right estimated time of arrival. They lived up to the commitment and they delivered. Kamal was timely checking with me on any queries related to the colour details and he was very precisely checking on the colours which i have shared and ultimately you could see the output in the below images , I 100% satisfied with the work they had put In this job. They are prompt and professional. The good part is I was always getting the timely updates and even once the job is created they have generated the invoice and shared it to me in the whatsapp, initially I was sceptical to give but now I trust goclutch. Great job goclutch, I definitely am planning to make it my go to garage",
  },
  {
    name: "Ram Knock",
    title: "Hyderabad",
    text: "Sandeep and his staff are too good ,blindly you guys can trust this service , amazing response on time to time basis, hazzle free and handy solutions....",
  },
  {
    name: "Anudeep Pinnamaneni",
    title: "Hyderabad",
    text: "The Go Clutch guys are very experienced; they will pick you up, and all the service photos and videos they take they will share on WhatsApp. They have been shared until my vehicle is dropped off; they will keep you updated. I am grateful to you guys; your services are excellent. Thanks to Go Clutch Car Services....",
  },
  {
    name: "Kathik",
    title: "Hyderabad",
    text: "I recently had my car serviced at Go Clutch, and I must say I'm very pleased with the experience. The staff was professional and courteous, and the service was completed in a timely manner. The quality of the work was exceptional, and the cost was reasonable. I highly recommend Go Clutch for all your car service needs....",
  },
  {
    name: "Dr. Greevani Prasad Vasireddy",
    title: "Hyderabad",
    text: "Had a best experience, my car recently met with an accident and had major dent. Mr.Raju from go clutch madhapur, made my car like a new one, removing all dents as like original design. I appreciate and thankful to Mr. Raju for his wonderful work....",
  },
  {
    name: "Hareesh Raj Sunkara",
    title: "Hyderabad",
    text: "I have been using Go Clutch services for quite sometime now. The quality of their work is superb and pricing is very less. Experienced mechanics, Service advisors are very humble, Sivaram - My service hubby is very friendly and explained me all the stuff. Thanks to Go Clutch Car Services. Recommendable.....",
  },
  {
    name: "Mani Thummala",
    title: "Hyderabad",
    text: "When I was looking for a reliable car mechanic, I have been introduced this service by a cab driver. I was impressed with their service. Hassle free, very reliable, prompt delivery, did not find any issues with them. Genuine people and you can trust them without a worry. Also maintaining the regular service of the car. I definitely recommend this service to anyone who is looking for any kind of problem with the car. You can have a peace of mind with them.....",
  },
  {
    name: "Rapaka Prasanth",
    title: "Hyderabad",
    text: "I have changed my center join bearing and cross t for my MAHINDRA TUV300 and the service was perfect thankyou team go clutch and Sandeep sir and maniraj for the service....",
  }
  // Add more testimonials if needed
];

const TestimonialSlider = () => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const maxSteps = testimonials.length;
  const [open, setOpen] = useState(false);
  const [selectedTestimonial, setSelectedTestimonial] = useState(null);

  const handleNext = () => {
    setActiveStep((prevActiveStep) =>
      Math.min(prevActiveStep + 1, maxSteps - 1)
    );
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => Math.max(prevActiveStep - 1, 0));
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handleOpenModal = (testimonial) => {
    setSelectedTestimonial(testimonial);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const truncateText = (text) => {
    return text.length > 120 ? text.substring(0, 120) + "..." : text;
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: isMobile ? "auto" : 400, // Dynamic height
        backgroundImage: `url(${
          process.env.PUBLIC_URL + "/homeBanners/testimonialCard.webp"
        })`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        px: isMobile ? 2 : 4, // Adjust padding for mobile
        paddingTop: "40px",
        marginBottom: isMobile ? "60px" : "100px",
      }}
    >
      <Box sx={{ width: "100%" }}>
        {/* Centered Text */}
        <Box
          sx={{ textAlign: "center", marginBottom: isMobile ? "30px" : "20px" }}
        >
          <Typography
            variant={isMobile ? "h5" : "h4"}
            component="div"
            color="common.white"
            sx={{ fontWeight: "bold" }}
          >
            Why Our Customers Love Go Clutch!
          </Typography>
        </Box>

        {/* Right-aligned Buttons */}
        <Box
          sx={{ textAlign: "right", marginBottom: isMobile ? "20px" : "0px" }}
        >
          <IconButton
            onClick={handleBack}
            disabled={activeStep === 0}
            sx={{
              padding: "8px",
              background: "orangered !important",
              marginRight: "10px",
            }}
          >
            <ChevronLeft style={{ color: "white" }} />
          </IconButton>
          <IconButton
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
            sx={{ padding: "8px", background: "orangered !important" }}
          >
            <ChevronRight style={{ color: "white" }} />
          </IconButton>
        </Box>
      </Box>

      <SwipeableViews
        axis={"x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        style={{ position: "relative", top: 0 }}
      >
        {isMobile
          ? testimonials.map((testimonial, index) => (
              <Box key={index} sx={{ padding: 2, width: "100%" }}>
                <Card
                  elevation={4}
                  sx={{
                    padding: 2,
                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                    borderRadius: 2,
                    width: "100%", // Full width for mobile
                  }}
                >
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      sx={{ fontWeight: "600", fontSize: "1.25rem", color: 'orangered' }}
                    >
                      {testimonial.name}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      sx={{
                        marginBottom: 1,
                        fontSize: "1rem",
                        fontWeight: 500,
                      }}
                    >
                      {testimonial.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.primary"
                      sx={{
                        fontSize: "0.95rem",
                        color: "#333",
                        lineHeight: 1.5,
                      }}
                    >
                      {truncateText(testimonial.text)}
                    </Typography>
                    <Button
                      size="small"
                      onClick={() => handleOpenModal(testimonial)}
                      sx={{
                        marginTop: 2,
                        color: "#1976d2",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                      }}
                    >
                      View More
                    </Button>
                  </CardContent>
                </Card>
              </Box>
            ))
          : testimonials.reduce((acc, curr, index) => {
              // Pairing testimonials for desktop view
              if (index % 2 === 0 || testimonials.length % 2 === 1) {
                const pair = testimonials.slice(index, index + 2);
                acc.push(
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "0 auto",
                      gap: 2,
                      marginTop: "24px",
                    }}
                  >
                    {pair.map((testimonial, pairIndex) => (
                      <Card
                        key={pairIndex}
                        elevation={4}
                        sx={{
                          width: "45%",
                          margin: "0 1%",
                          maxWidth: "none",
                          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                          borderRadius: 2,
                        }}
                      >
                        <CardContent>
                          <Typography
                            gutterBottom
                            variant="h5"
                            component="div"
                            sx={{ fontWeight: "600", fontSize: "1.25rem", color: 'orangered' }}
                          >
                            {testimonial.name}
                          </Typography>
                          <Typography
                            color="text.secondary"
                            sx={{
                              marginBottom: 1,
                              fontSize: "1rem",
                              fontWeight: 500,
                            }}
                          >
                            {testimonial.title}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.primary"
                            sx={{
                              fontSize: "0.95rem",
                              color: "#333",
                              lineHeight: 1.5,
                            }}
                          >
                            {truncateText(testimonial.text)}
                          </Typography>
                          <Button
                            size="small"
                            onClick={() => handleOpenModal(testimonial)}
                            sx={{
                              marginTop: 1,
                              color: "#1976d2",
                              fontWeight: "bold",
                              textTransform: "uppercase",
                            }}
                          >
                            View More
                          </Button>
                        </CardContent>
                      </Card>
                    ))}
                  </Box>
                );
              }
              return acc;
            }, [])}
      </SwipeableViews>

      {/* Modal for viewing the full testimonial */}
      <Dialog open={open} onClose={handleCloseModal}>
        <DialogTitle>{selectedTestimonial?.name}</DialogTitle>
        <DialogContent>
          <Typography gutterBottom>{selectedTestimonial?.title}</Typography>
          <Typography>{selectedTestimonial?.text}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TestimonialSlider;
